<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                  <b-form  @submit.prevent="handleSubmit(createData)" @reset.prevent="reset" >
                    <b-row>
                        <b-col lg="6" sm="12">
                        <ValidationProvider name="Organization" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="org_id"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                  {{ $t('org_pro.organization')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                  plain
                                  v-model="applicationData.org_id"
                                  :options="orgList"
                                  id="org_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                        <ValidationProvider name="Fiscal Year" vid="fiscal_year_id"  rules="required|min_value:1">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="fiscal_year_id"
                            slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                              {{$t('warehouse_config.fiscal_year')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                              plain
                              v-model="applicationData.fiscal_year_id"
                              :options="fiscalList"
                              id="fiscal_year_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                       <b-col lg="6" sm="12">
                        <ValidationProvider name="Apply By" vid="applicant_type_id " rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="applicant_type_id "
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                  {{ $t('applicationAllocationProgram.applyBy')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                  plain
                                  v-model="applicationData.applicant_type_id "
                                  :options="applyByList"
                                  id="applicant_type_id "
                                  :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                      </b-col>
                       <b-col lg="6" sm="12">
                        <ValidationProvider name="Applicant" vid="applicant_id " rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="applicant_id "
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                  {{ $t('applicationAllocationProgram.applicant')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                  plain
                                  v-model="applicationData.applicant_id "
                                  :options="applicantList"
                                  id="applicant_id "
                                  :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                        <ValidationProvider name="Production Season" vid="production_season_id"  rules="required|min_value:1">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="production_season_id"
                            slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                              {{$t('seedsConfig.productionSeason')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                              plain
                              v-model="applicationData.production_season_id"
                              :options="productionSeasonList"
                              id="production_season_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                        <ValidationProvider name="Crop Type" vid="crop_type"  rules="required|min_value:1">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="crop_type"
                            slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                              {{$t('seedsConfig.cropType')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                              plain
                              v-model="applicationData.crop_type_id"
                              :options="cropTypeList"
                              id="crop_type_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                        <ValidationProvider name="Crop Name" vid="crop_name_id"  rules="required|min_value:1">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="crop_name_id"
                            slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                              {{$t('seedsConfig.cropName')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                              plain
                              v-model="applicationData.crop_name_id"
                              :options="cropNameList"
                              id="crop_name_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <!-- <b-col lg="6" sm="12">
                        <ValidationProvider name="Variety" vid="variety"  rules="required|min_value:1">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="variety"
                          slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                              {{$t('applicationAllocationProgram.variety')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                              plain
                              v-model="applicationData.variety"
                              :options="varietyList"
                              id="crop_name_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                               @change="getVarietyInfo(data.crop_name_id)"
                              >
                              <template v-slot:first>
                                <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col> -->
                      <b-col lg="6" sm="12">
                        <ValidationProvider name="Seed Class" vid="seed_class"  rules="required|min_value:1">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="seed_class"
                            slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                              {{$t('seedsConfig.seedClass')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                              plain
                              v-model="applicationData.seed_class_id"
                              :options="seedClassList"
                              id="seed_class_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <!-- <b-col lg="6" sm="12">
                        <ValidationProvider name="Quantity" vid="quantity"  rules="required|min_value:1">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="quantity"
                            slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                              {{$t('applicationAllocationProgram.quantity')}} <span class="text-danger">*</span>
                            </template>
                              <b-form-input
                                id="quantity"
                                v-model="applicationData.quantity"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col> -->
                      <b-col lg="6" sm="12">
                        <ValidationProvider name="Measurement Unit" vid="measurement_unit_id"  rules="required|min_value:1">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="measurement_unit_id"
                            slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                              {{$t('seedsConfig.measurementUnit')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                              plain
                              v-model="applicationData.measurement_unit_id"
                              :options="measurementUnitList"
                              id="measurement_unit_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                    </b-row>
                    <b-row v-for="(setLevel, index) in applicationData.level_info" :key="index">
                        <b-col lg="5" sm="5">
                            <ValidationProvider name="Variety" vid='variety' rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="5"
                                label-for="variety"
                                slot-scope="{ valid, errors }"
                            >
                               <template v-slot:label>
                              {{$t('applicationAllocationProgram.variety')}} <span class="text-danger">*</span>
                            </template>
                             <b-form-input
                                id="quantity"
                                hidden
                                v-model="setLevel.variety_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <b-form-input
                                id="quantity"
                                readonly
                                v-model="setLevel.variety"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <!-- <b-form-select
                                plain
                                v-model="setLevel.variety_id"
                                :options="varietyList"
                                id="crop_name_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                </b-form-select> -->
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="5" sm="5">
                            <ValidationProvider name="Quantity" vid='quantity' rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="5"
                                label-for="quantity"
                                slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                                {{ $t('applicationAllocationProgram.quantity')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                id="quantity"
                                v-model="setLevel.quantity"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                            </ValidationProvider>
                        </b-col>
                    </b-row>
                    <div class="row">
                      <div class="col-sm-3"></div>
                      <div class="col text-right">
                        <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                        &nbsp;
                        <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                      </div>
                    </div>
                  </b-form>
                </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { applicationAllocationStore, applicationAllocationUpdate, companyFarmerInfoList, dealerList, varietyList } from '../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getEditData()
      this.applicationData = tmp
      const params = Object.assign({}, this.applicationData, {
            level_info: []
            })
      this.applicationData = params
      RestApi.getData(seedFertilizerServiceBaseUrl, varietyList + '/' + this.id).then(response => {
            if (response.success) {
                this.applicationData.level_info = []
                response.data.forEach(element => {
                     const stepName = {
                        variety_id: element.variety_id,
                        variety: this.getVarietyName(element.variety_id),
                        quantity: element.quantity
                    }
                    this.applicationData.level_info.push(stepName)
                })
            }
        })
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      applicationData: {
        id: '',
        org_id: 0,
        fiscal_year_id: 0,
        applicant_type_id: 0,
        applicant_id: 0,
        production_season_id: 0,
        seed_class_id: 0,
        crop_type_id: 0,
        crop_name_id: 0,
        level_info: [
            // {
            // variety_id: 0,
            // quantity: ''
            // }
        ],
        measurement_unit_id: 0,
        application_date: 0
      },
      applyByList: [
          { value: 1, text: this.$t('applicationAllocationProgram.organization') },
          { value: 2, text: this.$t('applicationAllocationProgram.farmer') },
          { value: 3, text: this.$t('applicationAllocationProgram.dealer') }
      ],
      cropNameList: [],
      applicantList: [],
      varietyList: [],
      newData: [],
      loader: false,
      search: {
        crop_name_id: 0
      }
    }
  },
  computed: {
    orgList: function () {
      return this.$store.state.orgList.filter(item => item.status === 0)
    },
    fiscalList: function () {
        const fiscalyearData = this.$store.state.commonObj.fiscalYearList
        return fiscalyearData.map(item => {
            if (this.$i18n.locale === 'bn') {
            return { value: item.value, text: item.text_bn }
            } else {
            return { value: item.value, text: item.text_en }
            }
        })
    },
    productionSeasonList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.productionSeasonList.filter(item => item.status === 1)
    },
    seedClassList: function () {
       return this.$store.state.SeedsFertilizer.commonObj.seedClassList.filter(item => item.status === 1)
    },
    cropTypeList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.CropTypeList.filter(item => item.status === 1)
    },
    // varietyList: function () {
    //     return this.$store.state.SeedsFertilizer.commonObj.VarietyList.filter(item => item.status === 1)
    // },
    measurementUnitList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.unitList
    }
  },
  watch: {
    'applicationData.crop_type_id': function (newVal, oldVal) {
      this.cropNameList = this.getCropNameList(newVal)
    },
    'applicationData.crop_name_id': function (newVal, oldVal) {
      this.varietyList = this.getVarietyList(newVal)
    },
    'applicationData.applicant_type_id': function (newVal, oldVal) {
        this.applicantList = this.getApplicantList(newVal)
    }
  },
  methods: {
    async createData () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }
      if (this.applicationData.id) {
        result = await RestApi.putData(seedFertilizerServiceBaseUrl, `${applicationAllocationUpdate}/${this.id}`, this.applicationData)
      } else {
        result = await RestApi.postData(seedFertilizerServiceBaseUrl, applicationAllocationStore, this.applicationData)
      }

      loadinState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.$store.dispatch('SeedsFertilizer/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    },
    getEditData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    getCropNameList (croptypeID) {
      const cropNameList = this.$store.state.SeedsFertilizer.commonObj.CropNameList.filter(item => item.status === 1)
      if (croptypeID) {
        return cropNameList.filter(cropName => cropName.crop_type_id === croptypeID)
      }
      return cropNameList
    },
    getVarietyList (cropNameId) {
        const variety = this.$store.state.SeedsFertilizer.commonObj.VarietyList.filter(doc => doc.crop_name_id === cropNameId)
        if (this.$i18n.locale === 'en') {
            variety.forEach(element => {
                    const stepName = {
                    variety_id: element.value,
                    variety: element.text_en,
                    quantity: ''
                }
                this.applicationData.level_info.push(stepName)
            })
        } else {
             variety.forEach(element => {
                    const stepName = {
                    variety_id: element.value,
                    variety: element.text_bn,
                    quantity: ''
                }
                this.applicationData.level_info.push(stepName)
            })
        }
    },
    getVarietyName (Id) {
        const variety = this.$store.state.SeedsFertilizer.commonObj.VarietyList.find(doc => doc.value === Id)
        if (variety) {
            return this.$i18n.locale === 'bn' ? variety.text_bn : variety.text_en
        } else {
            return null
        }
    },
    getApplicantList (appType) {
        switch (appType) {
            case 1:
                RestApi.getData(seedFertilizerServiceBaseUrl, companyFarmerInfoList).then(response => {
                    if (response.success) {
                        const data = response.data.filter(doc => doc.category_id === 1)
                        this.applicantList = data.map(item => {
                            if (this.$i18n.locale === 'en') {
                            return { value: item.id, text: item.company_name }
                            } else {
                            return { value: item.id, text: item.company_name_bn }
                            }
                        })
                    }
                })
            break
            case 2:
                RestApi.getData(seedFertilizerServiceBaseUrl, companyFarmerInfoList).then(response => {
                    if (response.success) {
                        const data = response.data.filter(doc => doc.category_id === 2)
                        this.applicantList = data.map(item => {
                            if (this.$i18n.locale === 'en') {
                            return { value: item.id, text: item.farmer_name }
                            } else {
                            return { value: item.id, text: item.farmer_name_bn }
                            }
                        })
                    }
                })
            break
            case 3:
                RestApi.getData(seedFertilizerServiceBaseUrl, dealerList).then(response => {
                    if (response.success) {
                        const data = response.data.filter(doc => doc.category === 1)
                        this.applicantList = data.map(item => {
                            if (this.$i18n.locale === 'en') {
                            return { value: item.id, text: item.name }
                            } else {
                            return { value: item.id, text: item.name_bn }
                            }
                        })
                    }
                })
            break
            default:
                break
        }
    }
  }
}
</script>
