<template>
    <b-container fluid>
        <iq-card>
            <template v-slot:body>
                <b-row>
                    <b-col md="4">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            :label="$t('warehouse_config.fiscal_year')"
                            label-for="email"
                        >
                            <v-select name="croptypeID"
                                v-model="search.fiscal_year_id"
                                label="text"
                                :options= fiscalList
                            />
                        </b-form-group>
                    </b-col>
                    <b-col  md="4">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            :label="$t('applicationAllocationProgram.applyBy')"
                            label-for="productionSeason"
                        >
                            <v-select name="croptypeID"
                                v-model="search.applicant_type_id"
                                label="text"
                                :options= applyByList
                            />
                        </b-form-group>
                    </b-col>
                    <b-col >
                        <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                    </b-col>
                </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{$t('applicationAllocationProgram.applicationList')}}</h4>
                    </template>
                    <template v-slot:headerAction1>

                    </template>
                    <template v-slot:headerAction>
                        <b-button variant="primary" v-b-modal.modal-4 @click="resetId">
                             {{  $t('globalTrans.add_new') }}
                        </b-button>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                                        <template v-slot:cell(index)="data">
                                        {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template v-slot:cell(fiscal_year)="data">
                                        {{ $n(data.item.fiscal_year.split('-')[0], { useGrouping: false }) + '-' + $n(data.item.fiscal_year.split('-')[1], { useGrouping: false }) }}
                                        </template>
                                        <template v-slot:cell(applicant_type_id)="data">
                                        <span class="capitalize" v-if="data.item.applicant_type_id == 1">{{ $t('applicationAllocationProgram.organization') }}</span>
                                        <span class="capitalize" v-else-if="data.item.applicant_type_id == 2">{{ $t('applicationAllocationProgram.farmer') }}</span>
                                        <span class="capitalize" v-else>{{ $t('applicationAllocationProgram.dealer') }}</span>
                                        </template>
                                        <template v-slot:cell(status)="data">
                                            <span class="badge badge-success" v-if="data.item.status == 1">{{$t('globalTrans.active')}}</span>
                                            <span class="badge badge-danger" v-else>{{$t('globalTrans.inactive')}}</span>
                                        </template>
                                        <template v-slot:cell(action)="data">
                                        <!-- <b-button v-b-modal.modal-5 variant=" iq-bg-warning mr-1 mb-1" size="sm" @click="edit(data.item)"><i class="fas fa-eye"></i></b-button> -->
                                        <b-button v-b-modal.modal-4 title="Edit" variant="iq-bg-success mr-1 mb-1" size="sm" @click="edit(data.item)"><i class="ri-ball-pen-fill m-0"></i></b-button>
                                        <b-button variant=" iq-bg-danger" size="sm" @click="remove(data.item)"><i class="ri-delete-bin-line m-0"></i></b-button>
                                        </template>
                                    </b-table>
                                    <b-pagination
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                        />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-4" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Form :id="editItemId" :key="editItemId"/>
        </b-modal>
        <b-modal id="modal-5" size="lg" :title="this.$t('globalTrans.view')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Details :id="editItemId" :key="editItemId"/>
        </b-modal>
        <!-- <pre>{{search}}</pre> -->
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import Form from './Form'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { applicationAllocationList, applicationAllocationToggleStatus } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'

export default {
    mixins: [ModalBaseMasterList],
    components: {
        Form
    },
    data () {
        return {
            search: {
                fiscal_year_id: 0,
                applicant_type_id: 0
            },
            applyByList: [
                { value: 1, text: this.$t('applicationAllocationProgram.organization') },
                { value: 2, text: this.$t('applicationAllocationProgram.farmer') },
                { value: 3, text: this.$t('applicationAllocationProgram.dealer') }
            ]
        }
    },
    computed: {
        orgList: function () {
            return this.$store.state.orgList.filter(item => item.status === 0)
        },
        fiscalList: function () {
            const fiscalyearData = this.$store.state.commonObj.fiscalYearList
            return fiscalyearData.map(item => {
                if (this.$i18n.locale === 'bn') {
                return { value: item.value, text: item.text_bn }
                } else {
                return { value: item.value, text: item.text_en }
                }
            })
        },
        productionSeasonList: function () {
          return this.$store.state.SeedsFertilizer.commonObj.productionSeasonList.filter(item => item.status === 1)
        },
        CropTypeList: function () {
          return this.$store.state.SeedsFertilizer.commonObj.CropTypeList.filter(item => item.status === 1)
        },
        formTitle () {
            return (this.editItemId === 0) ? this.$t('applicationAllocationProgram.applicationForAllocation') + ' ' + this.$t('globalTrans.entry') : this.$t('applicationAllocationProgram.applicationForAllocation') + ' ' + this.$t('globalTrans.update')
        },
        columns () {
            const labels = [
                { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
                { label: this.$t('warehouse_config.fiscal_year'), class: 'text-left' },
                { label: this.$t('seedsConfig.productionSeason'), class: 'text-left' },
                { label: this.$t('applicationAllocationProgram.applyBy'), class: 'text-left' },
                { label: this.$t('seedsConfig.cropType'), class: 'text-left' },
                { label: this.$t('seedsConfig.cropName'), class: 'text-left' },
                 { label: this.$t('seedsConfig.seedClass'), class: 'text-left' },
                { label: this.$t('globalTrans.status'), class: 'text-center' },
                { label: this.$t('globalTrans.action'), class: 'text-center' }
            ]

            /*eslint-disable */
            let keys = []

            if (this.$i18n.locale === 'bn') {
                keys = [
                    { key: 'index' },
                    { key: 'fiscal_year' },
                    { key: 'production_season_name_bn' },
                    { key: 'applicant_type_id' },
                    { key: 'crop_type_name_bn' },
                    { key: 'crop_name_name_bn' },
                    { key: 'seed_class_bn' },
                    { key: 'status' },
                    { key: 'action' }
                ]
            } else {
                keys = [
                    { key: 'index' },
                    { key: 'fiscal_year' },
                    { key: 'production_season_name' },
                    { key: 'applicant_type_id' },
                    { key: 'crop_type_name' },
                    { key: 'crop_name_name' },
                    { key: 'seed_class' },
                    { key: 'status' },
                    { key: 'action' }
                ]
            }

            return labels.map((item, index) => {
                return Object.assign(item, keys[index])
            })
        }
    },
    async created () {
        this.loadData()
    },
    mounted () {
        core.index()
    },
    methods: {
        dataChange () {
            this.loadData()
        },
        async searchData () {
            this.loadData()
        },
        remove (item) {
            this.changeStatus(seedFertilizerServiceBaseUrl, applicationAllocationToggleStatus, item, null, null)
        },
        loadData () {
            const params = Object.assign({}, this.search, {
                fiscal_year_id: this.search.fiscal_year_id.value,
                applicant_type_id: this.search.applicant_type_id.value,
                page: this.pagination.currentPage, per_page: this.pagination.perPage })
            this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
            RestApi.getData(seedFertilizerServiceBaseUrl, applicationAllocationList, params).then(response => {
                if (response.success) {
                    this.$store.dispatch('setList', this.getRelationalData(response.data))
                    this.paginationData(response.data)
                } else {
                    this.$store.dispatch('setList', [])
                }
                this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            })
        },
        getRelationalData (data) {
            const productionSeasonList = this.$store.state.SeedsFertilizer.commonObj.productionSeasonList
            const cropTypeListList = this.$store.state.SeedsFertilizer.commonObj.CropTypeList
            const cropNameListList = this.$store.state.SeedsFertilizer.commonObj.CropNameList
            const seedClassList = this.$store.state.SeedsFertilizer.commonObj.seedClassList
            const fiscalyearData = this.$store.state.commonObj.fiscalYearList
            const organizationList = this.$store.state.orgList
            const divisionList = this.$store.state.commonObj.divisionList
            const districtList = this.$store.state.commonObj.districtList
            const spcOfficeList = this.$store.state.SeedsFertilizer.commonObj.spcOfficeList
            const zoneOfficeList = this.$store.state.SeedsFertilizer.commonObj.zoneOfficeList
            const carryoverList = this.$store.state.SeedsFertilizer.commonObj.carryoverList
            const measurementUnitList = this.$store.state.SeedsFertilizer.commonObj.unitList
            return data.data.map(item => {
                const productionSeasonObject = productionSeasonList.find(productionSeason => productionSeason.value === item.production_season_id)
                const cropTypeListObject = cropTypeListList.find(cropTypeList => cropTypeList.value === item.crop_type_id)
                const cropNameListObject = cropNameListList.find(cropNameList => cropNameList.value === item.crop_name_id)
                const fiscalyearObject = fiscalyearData.find(fiscalyear => fiscalyear.value === item.fiscal_year_id)
                const seedClassObject = seedClassList.find(seedClass => seedClass.value === item.seed_class_id)
                const orgObject = organizationList.find(oganization => oganization.value === item.org_id)
                const divisionObject = divisionList.find(division => division.value === item.division_id)
                const districtObject = districtList.find(district => district.value === item.district_id)
                const spcOfficeObject = spcOfficeList.find(spcOffice => spcOffice.value === item.from_spc_office_id)
                const zoneOfficeObject = zoneOfficeList.find(zoneOffice => zoneOffice.value === item.to_sales_office_id)
                const carryOverObject = carryoverList.find(carryover => carryover.value === item.carryovers_id)
                const measurementUnitObject = measurementUnitList.find(measurementUnit => measurementUnit.value === item.measurement_unit_id)
                const productionSeasonData = {
                    production_season_name: productionSeasonObject ? productionSeasonObject.text_en : '',
                    production_season_name_bn: productionSeasonObject ? productionSeasonObject.text_bn : ''
                }
                const cropTypeListData = {
                    crop_type_name: cropTypeListObject ? cropTypeListObject.text_en : '',
                    crop_type_name_bn: cropTypeListObject ? cropTypeListObject.text_bn : ''
                }
                const cropNameListData = {
                    crop_name_name: cropNameListObject ? cropNameListObject.text_en : '',
                    crop_name_name_bn: cropNameListObject ? cropNameListObject.text_bn : ''
                }
                const FiscalYearData = {
                    fiscal_year: fiscalyearObject ? fiscalyearObject.text_en : '',
                    fiscal_year_bn: fiscalyearObject ? fiscalyearObject.text_bn : ''
                }
                const seedClasseData = {
                    seed_class: seedClassObject ? seedClassObject.text_en : '',
                    seed_class_bn: seedClassObject ? seedClassObject.text_bn : ''
                }
                const orgData = {
                    org_name: orgObject ? orgObject.text_en : '',
                    org_name_bn: orgObject ? orgObject.text_bn : ''
                }
                const divisionData = {
                    division_name: divisionObject ? divisionObject.text_en : '',
                    division_name_bn: divisionObject ? divisionObject.text_bn : ''
                }
                const districtData = {
                    district_name: districtObject ? districtObject.text_en : '',
                    district_name_bn: districtObject ? districtObject.text_bn : ''
                }
                const spcOfficeData = {
                    spcOffice_name: spcOfficeObject ? spcOfficeObject.text_en : '',
                    spcOffice_name_bn: spcOfficeObject ? spcOfficeObject.text_bn : ''
                }
                const zoneOfficeData = {
                    zoneOffice_name: zoneOfficeObject ? zoneOfficeObject.text_en : '',
                    zoneOffice_name_bn: zoneOfficeObject ? zoneOfficeObject.text_bn : ''
                }
                const carryOverData = {
                    carryOver_name: carryOverObject ? carryOverObject.text_en : '',
                    carryOver_name_bn: carryOverObject ? carryOverObject.text_bn : ''
                }
                const measurementUnitData = {
                    measurementUnit_name: measurementUnitObject ? measurementUnitObject.text_en : '',
                    measurementUnit_name_bn: measurementUnitObject ? measurementUnitObject.text_bn : ''
                }
                return Object.assign({}, item, productionSeasonData, cropTypeListData, cropNameListData, FiscalYearData, seedClasseData, orgData, divisionData, districtData, spcOfficeData, zoneOfficeData, carryOverData, measurementUnitData)
            })
        }
    }
}
</script>
