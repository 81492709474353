
// Application Allocation api
export const applicationAllocationList = 'seeds/seeds-manage/seeds-allocation-app/list'
export const applicationAllocationStore = 'seeds/seeds-manage/seeds-allocation-app/store'
export const applicationAllocationUpdate = 'seeds/seeds-manage/seeds-allocation-app/update'
export const applicationAllocationToggleStatus = 'seeds/seeds-manage/seeds-allocation-app/toggle-status'
export const applicationAllocationDestroy = 'seeds/seeds-manage/seeds-allocation-app/destroy'
export const varietyList = 'seeds/seeds-manage/seeds-allocation-app/varietyList'
export const companyFarmerInfoList = 'seeds/config/company-farmers/listAll'
export const dealerList = 'fertilizer/config/dealer-setup/list-all'
